import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VShowSlide from "v-show-slide";
import { i18n } from "./i18n.js"





const app = createApp(App);
app.use(router)
app.use(i18n)
app.mount("#app");
app.use(VShowSlide);
app.config.productionTip = false;

import Main from "./layouts/Main.vue";
import Support from "./layouts/Support.vue";

app.component("main-layout", Main);
app.component("support-layout", Support);
