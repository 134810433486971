import { createRouter, createWebHashHistory } from "vue-router";

const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else if (to.hash) {
    return {
      selector: to.hash,
    };
  } else {
    return { x: 0, y: 0 };
  }
};


const routes = [
  {
    path: "/",
    name: "Main",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/Main.vue"),
  },
  {
    path: "/index.html",
    redirect: { name: "Main" },
  },
  {
    path: "/qr/*",
    redirect: { name: "Main" },
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/Contact.vue"),
  },
  {
    path: "/contact-confirmation",
    name: "ContactConfirmation",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/ContactConfirmation.vue"),
  },
  {
    path: "/tv",
    redirect: { name: "Support" },
  },
  {
    path: "/support",
    name: "Support",
    meta: {
      layout: "support",
    },
    component: () => import("@/pages/Support.vue"),
  },
  {
    path: "/support/:categoryId/:subcategoryId/:itemId",
    name: "SupportItem",
    props: true,
    meta: {
      layout: "support",
    },
    component: () => import("@/pages/Support.vue"),
  },
  {
    path: "/modules/:moduleKey",
    name: "ModuleItem",
    props: true,
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/ModulesPage.vue"),
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: () => import("@/pages/NotFound.vue"),
  },
  {
    path: "/x",
    name: "CerumX",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/CerumX.vue"),
  },
  {
    path: "/innsjekk",
    name: "Innsjekk",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/Checkin.vue"),
  },
  {
    path: "/renhold",
    name: "Renhold",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/Renhold.vue"),
  },
  {
    path: "/plus",
    name: "Plus",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/Renhold.vue"),
  },
  {
    path: "/pluss",
    name: "Pluss",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/Renhold.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/AboutUs.vue"),
  },
  {
    path: "/construct",
    name: "ByggAnlegg",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/ByggAnlegg.vue"),
  },
  {
    path: "/construct/app",
    name: "ConstructApp",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/ConstructAppPage.vue"),
  },
  {
    path: "/industry",
    name: "Industry",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/Industry.vue"),
  },
  {
    path: "/plus",
    name: "CerumPlus",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/CerumPlus.vue"),
  },
  {
    path: "/assetmaintenance",
    name: "AssetMaintenance",
    meta: {
      layout: "support",
    },
    component: () => import("@/pages/AssetMaintenance.vue"),
  },
  {
    path: "/assetmaintenance/:categoryId/:subcategoryId/:itemId",
    name: "AssetMaintenanceItem",
    props: true,
    meta: {
      layout: "support",
    },
    component: () => import("@/pages/AssetMaintenance.vue"),
  },
  {
    path: "/livemesse",
    name: "LiveMesse",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/LiveMesse.vue"),
  },
  {
    path: "/messe",
    name: "Messe",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/LiveMesse.vue"),
  },
  {
    path: "/webinar",
    name: "Webinar",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/LiveMesse.vue"),
  },
  {
    path: "/landingpage11",
    name: "LandingPageFirstWebinar",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/LandingPageFirstWebinar.vue"),
  },
  {
    path: "/landingpage14",
    name: "LandingPageSecondWebinar",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/LandingPageSecondWebinar.vue"),
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    meta: {
      layout: "main",
    },
    component: () => import("@/pages/NewsletterPage.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior,
});

export default router;


